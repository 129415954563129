import * as React from "react";
import Layout from "../components/Layout";

import profile_1 from "../img/profile/profile_1.png";
import profile_2 from "../img/profile/profile_2.png";

const NotFoundPage = () => (
  <Layout>
    <div className="content">
      <section className="section section--gradient" style={{backgroundColor: "#E4F2FC"}}>
        <div className="section">
          <div className="columns content is-medium">
            <div className="column is-8 is-offset-2">
              <h2 className="has-text-weight-semibold is-size-2">
                自己紹介
              </h2>
              <img
                className="margin-btm-0"
                src={profile_1}
                alt=""
                width="100%"
                height="auto"
              />
              <img
                className="margin-btm-0"
                src={profile_2}
                alt=""
                width="100%"
                height="auto"
              />
              <figure class="image">
              <iframe width="640" height="360" src="https://www.youtube.com/embed/Yr-zuoqn4jc?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </figure>
              <h3 className="has-text-weight-semibold is-size-3">
              メディア掲載
              </h3>
              <p>
              GrowS（グロース） -スポーツで本気のキッカケを見つけるメディア- <br />
              <a href="https://grows-rtv.jp/contents/article/1983" target="_blank" rel="noopener noreferrer">道が無ければ 自分で切り拓く！” ラクロス選手 中村弘一(27) 弱小大学、コネなし、情報なし から本場アメリカでプロ選手になった男の サバイバルに勝ち残った原動力とは？</a><br /><br />
               
              United Japanese researchers Around the world　一般社団法人 海外日本人研究者ネットワーク<br />
              <a href="https://www.uja-info.org/post/_g00607_2028%E5%B9%B4la%E4%BA%94%E8%BC%AA%E3%81%AE%E3%83%A9%E3%82%AF%E3%83%AD%E3%82%B9%E3%81%A6-%E9%87%91%E3%83%A1%E3%83%80%E3%83%AB%E7%8D%B2%E5%BE%97%EF%BC%81" target="_blank" rel="noopener noreferrer">2028年LA五輪のラクロスで金メダル獲得！</a><br /><br />

              
              岩倉高校ラクロス部のBLOG　<br />
              <a href="https://blog.goo.ne.jp/iwakuralax/c/f8958923688b38f744382a4b496f47cb/2" target="_blank" rel="noopener noreferrer"> 中村弘一選手よりメッセージ　エピソード１　ラクロスを通じて広がった世界（国内編）</a><br />
              </p>

            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
